import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmModal = ({ show, handleClose, handleConfirm }) => {
  return (
    <Modal style={{ zIndex: "1055" }} show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmação de Atualização</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Tem certeza que deseja atualizar o curso? Os áudios antigos serão apagados e substituídos pelos novos.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
