import React, { useEffect, useState, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import video from './arquivos/popup.mp4'; // Importando o vídeo

const PopupVideo = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [muted, setMuted] = useState(true); // Controla o mute
  const videoRef = useRef(null); // Referência para o elemento de vídeo

  const popupStartDate = new Date('2024-10-15'); // Data de início
  const popupEndDate = new Date('2024-10-19'); // Data de fim

  useEffect(() => {
    const today = new Date();
    const lastShown = localStorage.getItem('popupLastShown'); // Usando localStorage agora
    const lastShownDate = lastShown ? new Date(lastShown) : null;

    // Verificar se a data de hoje está dentro do intervalo e se o popup já foi mostrado hoje
    if (today >= popupStartDate && today <= popupEndDate && (!lastShownDate || lastShownDate.getDate() !== today.getDate())) {
      setShowPopup(true);
    }
  }, []);

  useEffect(() => {
    // Tentar iniciar o vídeo manualmente quando o popup abrir
    if (showPopup && videoRef.current) {
      videoRef.current.play().catch(error => {
        console.log("Autoplay foi bloqueado. O usuário precisará interagir com o vídeo para reproduzir.");
      });
    }
  }, [showPopup]);

  const closePopup = () => {
    setShowPopup(false);
    localStorage.setItem('popupLastShown', new Date().toString()); // Salvar no localStorage
  };

  // Função para habilitar o som do vídeo
  const enableSound = () => {
    setMuted(false);
    if (videoRef.current) {
      videoRef.current.muted = false;
      videoRef.current.play(); // Tenta iniciar o vídeo novamente com som
    }
  };

  return (
    <Modal style={{ zIndex: 1050 }} show={showPopup} onHide={closePopup} centered>
      <Modal.Header closeButton>
        <Modal.Title>Assista o vídeo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <video 
          ref={videoRef} // Adicionando a referência ao vídeo
          controls 
          autoPlay 
          muted={muted} // Começa mudo
          style={{ width: '100%', height: '500px' }}
        >
          <source src={video} type="video/mp4" /> 
        </video>
        {muted && (
          <div style={{ marginTop: '10px', textAlign: 'center' }}>
            <Button variant="primary" onClick={enableSound}>
              Ativar Som
            </Button>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closePopup}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupVideo;
